import { breakpointsTailwind } from '@vueuse/core'

export default function () {
	const { isMobile: initialMobile } = useDevice()

	const isMobile = ref(initialMobile)
	const isMobileQuery = useMediaQuery(
		`(max-width: ${breakpointsTailwind.sm}px`,
	)

	watchImmediate(isMobileQuery, (value) => {
		isMobile.value = value
	})

	return isMobile
}
